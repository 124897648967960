import { isSameDay } from 'date-fns';
import { Store } from './store';
import { authStore } from './authStore';
import { Lane, ProjectLane } from './types/lanes';

interface ScrumMateData extends Object {
  lanes: Lane[];
  projects: any[];
  stories: any[];
}

class ScrumMateStore extends Store<ScrumMateData> {
  // eslint-disable-next-line class-methods-use-this
  protected data(): ScrumMateData {
    return {
      lanes: [],
      projects: [],
      stories: [],
    };
  }

  public fetchData(): any {
    fetch(process.env.VUE_APP_SERVER_URL, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${authStore.getState().credential}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        this.handleData(response);
      });

    // this.handleData(mockData);
  }

  private handleData(data: any) {
    this.registerLanes(data);
    this.registerProjects(data);
    this.registerStories(data);
  }

  private registerLanes(data: any) {
    const lanes = data.data?.board?.columns;
    const colors: Record<string, string> = {
      'To do': '#a2a2a2',
      'In progress': '#f0a020',
      'Functional & Design implementation check': '#2080f0',
      Completed: '#18a058',
    };

    if (lanes) {
      this.state.lanes = lanes
        .map((lane: any) => ({
          id: lane.id,
          color: colors[lane.name],
          name: lane.name,
          order: lane.order,
        }))
        .sort((a: Lane, b: Lane) => (a.order >= b.order ? 1 : -1));
    }
  }

  private registerProjects(data: any) {
    const projects = data.data?.board?.projects;
    const { stories } = data.data;
    const lanes = this.state.lanes.slice();
    const finishedLane = lanes.slice().pop()?.id;

    if (projects && stories) {
      this.state.projects = projects
        .filter((project: any) => stories.some((story: any) => story.idProject === project.id))
        .map((project: any) => ({
          id: project.id,
          name: project.name,
          lanes: lanes.map((lane: Lane) => {
            const projectLane = JSON.parse(JSON.stringify(lane)) as ProjectLane;
            const laneStories = stories
              .filter((story: any) => story.idColumn === lane.id && story.idProject === project.id);
            const points = laneStories.reduce((acc: number, cur: any) => acc + cur.size, 0);

            projectLane.points = points;

            return projectLane;
          }),
          points: stories.filter((story: any) => story.idProject === project.id)
            .reduce(
              (acc: any, cur: any) => {
                acc.total += cur.size;

                if (cur.idColumn === finishedLane) {
                  acc.done += cur.size;
                }

                return acc;
              },
              {
                done: 0,
                total: 0,
              },
            ),
        }))
        .sort((a:any, b:any) => {
          if (a.points.done === a.points.total) {
            return 1;
          }
          if (b.points.done === b.points.total) {
            return -1;
          }

          return a.points.total > b.points.total ? -1 : 1;
        });
    }
  }

  private registerStories(data: any) {
    const stories = data.data?.stories;

    this.state.stories = stories.filter((story: any) => {
      if (story.columnName === 'Completed') {
        return false;
      }
      if (story.blocked) {
        return true;
      }

      const today = new Date(Date.now());
      const { dueDate } = story;
      return dueDate && isSameDay(today, new Date(dueDate));
    });
  }

  public getTotalPoints(): number {
    return this.state.projects.reduce((acc: number, cur: any) => acc + cur.points.total, 0);
  }

  public getBiggestProject(): any {
    return this.state.projects.reduce((acc: any, cur: any) => {
      if (acc) {
        return acc.points.total < cur.points.totel ? cur : acc;
      }

      return cur;
    }, null);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const scrumMateStore: ScrumMateStore = new ScrumMateStore();
