import { createApp } from 'vue';
import vue3GoogleLogin from 'vue3-google-login';
import App from './App.vue';

const app = createApp(App);
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_CLIENT_ID,
  scope: 'email',
  prompt: 'consent',
  fetch_basic_profile: true,
});
app.mount('#app');
