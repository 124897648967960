import { Store } from './store';

interface authData extends Object {
  credential: string | null;
}

class AuthStore extends Store<authData> {
  // eslint-disable-next-line class-methods-use-this
  protected data(): authData {
    return {
      credential: null,
    };
  }

  public handleAuthResponse(response: any) {
    this.state.credential = response.credential;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const authStore: AuthStore = new AuthStore();
