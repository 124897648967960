import { reactive, readonly } from 'vue';

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/ban-types
export abstract class Store<T extends Object> {
  protected state: T;

  constructor() {
    const data = this.data();
    this.setup(data);
    this.state = reactive(data) as T;
    this.process();
  }

  protected abstract data(): T

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  protected setup(data: T): void {}

  // Todo think of a better name;
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  protected process(): void {}

  public getState(): T {
    return readonly(this.state) as T;
  }
}
